import { Button, Form } from 'react-bootstrap';
import { Component } from 'react';
import SelectDraftList from '../SelectDraftList';
import NewDraftModal from '../NewDraftModal';
import { getFilteredDraftsOrSubmissionList } from '../../common/DraftSubmissionUtils';
import CosaMpsFilterButtons, { FilterButtonState } from '../../common/CosaMpsFilterButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './SelectDraftContainer.css';
import { injectIntl } from 'react-intl';

export class SelectDraftContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewDraftModal: false,
            filteredText: '',
            filterButtonState: FilterButtonState.NO_BUTTON_SELECTED
        };
    }

    onNewDraftButtonClicked = () => {
        this.setState({ showNewDraftModal: true });
    };

    onNewDraftSubmitted = (newDraft) => {
        this.props.onNewDraftSubmitted(newDraft);
        this.closeNewDraftModal();
    };

    closeNewDraftModal = () => {
        this.setState({
            showNewDraftModal: false
        });
    };

    onFilterTextChange = (evt) => {
        const filteredText = evt.target.value;
        this.setState({ filteredText: filteredText });
    };

    onFilterButtonClicked = (newState) => {
        this.setState({ filterButtonState: newState });
    };

    render() {
        const filteredDraftList = getFilteredDraftsOrSubmissionList(
            this.props.draftList,
            this.state.filteredText,
            this.state.filterButtonState
        );

        return (
            <div>
                <Form.Group>
                    <Form.Control
                        type='text'
                        ref={this.props.inputRef }
                        onChange={this.onFilterTextChange}
                        value={this.state.filteredText}
                        placeholder={this.props.intl.formatMessage({ id: 'search-drafts-submissions' })}
                    />
                </Form.Group>

                <CosaMpsFilterButtons onFilterButtonClicked={this.onFilterButtonClicked} />

                <Button onClick={this.onNewDraftButtonClicked} className='new-draft-button'>
                    <FontAwesomeIcon icon={faPlus} className='new-draft-button-icon' />
                    {this.props.intl.formatMessage({ id: 'new-draft' })}
                </Button>

                <SelectDraftList
                    draftList={filteredDraftList}
                    onDraftSelected={this.props.onDraftSelected}
                    selectedDraft={this.props.selectedDraft}
                />

                <div>
                    <NewDraftModal
                        show={this.state.showNewDraftModal}
                        handleSubmit={this.onNewDraftSubmitted}
                        handleClose={this.closeNewDraftModal}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(SelectDraftContainer);
