// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Form, Row } from 'react-bootstrap';
import BottomRowButtonActions from '../BottomRowButtonActions';
import SaveDraftModal from '../SaveDraftModal';
import SubmitDraftModal from '../controls/modal/SubmitDraftModal';
import CreatePpkgModal from './CreatePpkgModal';
import ProfilesContainer from './profiles/ProfilesContainer';
import EditProfileSettingContainer from './profiles/EditProfileSettingsContainer';
import { CreateNewGuid } from '../../Utils';
import { faSave, faUpload, faDownload, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deepClone } from '../mps/MpsUtils';
import { aquireTokenSilentOrPopup, createAuthorizationHeaders, getAccountId } from '../../auth/MsalUtils';
import API from '../../API';
import BackConfirmationDialog from '../controls/modal/BackConfirmationDialog';
import LoadingSpinner from '../common/LoadingSpinner';

const fileDownload = require('js-file-download');

export class CosaContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSaveDraftModal: false,
            showSubmitDraftModal: false,
            showPpkgModal: false,
            selectedProfileId: null,
            loading: false,
            schema: null,
            profiles: null,
            assets: [],
            isEdited: false,
            showBackConfirmation: false,
            updatedDraftDetails: null,
            isDraft: false,
            isReadyForSubmission: false
        };
        this.inputRef = createRef();
    }

    intl = this.props.intl;

    componentWillMount() {
        this.setState({ loading: true, loadingMessageId: 'getting-latest-profiles' });
        this.getSettingSchema();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading !== prevState.loading) {
            this.inputRef?.current?.focus(); // Testing library doesn't support refs
        }
    }

    getSettingSchema() {
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken
                }
            };

            API.get('/cosa/settingsSchema', headers)
                .then((response) => {
                    this.setState({ schema: response.data });
                    this.getDraft();
                })
                .catch((error) => {
                    console.log(error);
                    this.props.showErrorPage();
                });
        });
    }

    getDraft() {
        this.setState({ loading: true, loadingMessageId: 'getting-latest-profiles' });
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);
            const userGuid = getAccountId();

            API.get('/users/' + userGuid + '/cosa/drafts/' + this.props.draft.draftGuid, headers)
                .then((response) => {
                    this.setState({
                        profiles: response.data.profiles,
                        loading: false,
                        isEdited: false,
                        selectedProfileId: null,
                        isDraft: response.data.isDraft,
                        isReadyForSubmission: response.data.isReadyForSubmission
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.props.showErrorPage();
                });
        });
    }

    updateProfiles = (updatedProfiles) => {
        this.setState({ profiles: updatedProfiles, isEdited: true });
    };

    updateAssets = (evt) => {
        let assets = deepClone(this.state.assets);
        let index = -1;
        for (let i = 0; i < assets.length; i++) {
            if (assets[i].name === evt.name) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            assets.push(evt);
        } else {
            assets[index] = evt;
        }
        this.setState({ assets: assets, isEdited: true });
    };

    sendGeneratePpkgRequest = (option) => {
        this.setState({ loading: true, loadingMessageId: 'creating-ppkg' });
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken
                }
            };

            const userGuid = getAccountId();
            const endpoint = `/users/${userGuid}/cosa/createPpkg/${this.props.draft.draftGuid}/${option}`;
            API.get(endpoint, headers)
                .then((response) => {
                    this.pollPpkgStatus();
                })
                .catch((error) => {
                    console.log(error);
                    this.pushErrorToast('package-download-failure');
                    this.setState({ loading: false });
                });
        });
    };

    pollPpkgStatus() {
        setTimeout(() => {
            aquireTokenSilentOrPopup((tokenResponse) => {
                const headers = {
                    headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken
                    }
                };

                const userGuid = getAccountId();
                API.get('/users/' + userGuid + '/cosa/ppkgGenerationStatus', headers)
                    .then((response) => {
                        // Package creation can take some time, so need to poll rather
                        if (response.data.runtimeStatus === 'Running' || response.data.runttimeStatus === 'Pending') {
                            this.pollPpkgStatus();
                            return;
                        } else if (response.data.runtimeStatus === 'Completed') {
                            if (response.data.output.success) {
                                fetch(`data:application/octet-stream;base64,${response.data.output.data}`)
                                    .then(async (res) => {
                                        fileDownload(await res.blob(), 'Microsoft.Windows.Cosa.Desktop.Client.ppkg');
                                        this.pushSuccessToast('package-download-success');
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.showPackageDownloadFailureToast();
                                    });
                            } else {
                                this.showPackageDownloadFailureToast();
                            }
                            this.setState({ loading: false });
                        } else {
                            this.showPackageDownloadFailureToast();
                            this.setState({ loading: false });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.showPackageDownloadFailureToast();
                        this.setState({ loading: false });
                    });
            });
        }, 1000);
    }

    showPackageDownloadFailureToast() {
        this.pushErrorToast('package-download-failure');
    }

    saveDraftRequest(message) {
        this.setState({ loading: true, loadingMessageId: 'saving-draft' });
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken
                }
            };

            const userGuid = getAccountId();
            API.post(
                '/users/' + userGuid + '/cosa/drafts/' + this.props.draft.draftGuid,
                {
                    profiles: this.state.profiles,
                    assets: this.state.assets,
                    message: message
                },
                headers
            )
                .then(() => {
                    this.pushSuccessToast('save-draft-success');
                    this.getDraft();
                })
                .catch((error) => {
                    console.log(error);
                    this.pushErrorToast('save-draft-failure');
                    this.setState({ loading: false });
                });
        });
    }

    saveDraft(message) {
        this.saveDraftRequest(message);
    }

    sendSubmitDraftReqeust(message) {
        this.setState({ loading: true, loadingMessageId: 'submitting-draft' });
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken
                }
            };

            const userGuid = getAccountId();
            const endpoint = `/users/${userGuid}/cosa/submission/${this.props.draft.draftGuid}`;
            API.post(
                endpoint,
                {
                    draftDetails: this.state.updatedDraftDetails,
                    profiles: this.state.profiles,
                    assets: this.state.assets,
                    message: null
                },
                headers
            )
                .then(() => {
                    this.pushSuccessToast('submit-draft-success');
                    this.getDraft();
                })
                .catch((error) => {
                    console.log(error);
                    this.pushErrorToast('submit-draft-failure');
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    }

    submitDraft(message) {
        this.handleClose('showSubmitDraftModal');
        this.sendSubmitDraftReqeust(message);
    }

    generatePpkg = (option) => {
        this.setState({ showPpkgModal: false });
        this.sendGeneratePpkgRequest(option);
    };

    handleShow = (showVariable) => {
        const state = { ...this.state };
        state[showVariable] = true;
        this.setState(state);
    };

    handleClose = (closeVariable) => {
        const state = { ...this.state };
        state[closeVariable] = false;
        this.setState(state);
    };

    onProfileSelected = (profile) => {
        this.setState({ selectedProfileId: profile.targetRefs[0].id });
    };

    onProfileDeselected = () => {
        this.setState({ selectedProfileId: null });
    };

    goBackToLanding() {
        this.props.onBackButtonClicked();
        this.setState({ showBackConfirmation: false });
    }

    onBackButtonClicked() {
        if (!this.state.isEdited) {
            this.props.onBackButtonClicked();
            return;
        }

        this.setState({ showBackConfirmation: true });
    }

    showProfilesContainer() {
        const profilesToShow = this.state.profiles.map((profile) => profile.profile);
        return (
            <div>
                <Row style={{ marginBottom: '8px' }}>
                    <Col md={1}>
                        <Button onClick={() => this.onBackButtonClicked()} style={{ width: '76px' }}>
                            <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: '4px' }} />
                            {this.props.intl.formatMessage({ id: 'back' })}
                        </Button>
                    </Col>
                    <Col md={{ span: 3, offset: 4 }}>
                        <p className='text-center' style={{ fontWeight: 600, fontSize: 'larger' }}>
                            {this.props.draft.title}
                        </p>
                    </Col>
                </Row>
                <ProfilesContainer
                    inputRef={this.inputRef}
                    profiles={profilesToShow}
                    onProfileSelected={this.onProfileSelected}
                    onNewProfileCreated={this.onNewProfileCreated}
                    onProfileDeleted={this.onProfileDeleted}
                    onProfileNameChanged={this.onProfileNameChanged}
                />
            </div>
        );
    }

    getProfileFromId(id) {
        let selectedProfileId = null;
        for (let i = 0; i < this.state.profiles.length; i++) {
            if (this.state.profiles[i].target.id === id) {
                selectedProfileId = this.state.profiles[i];
                break;
            }
        }

        return selectedProfileId;
    }

    showProfileSelectedContainer() {
        const selectedProfile = this.getProfileFromId(this.state.selectedProfileId);
        return (
            <EditProfileSettingContainer
                schema={this.state.schema}
                profile={selectedProfile}
                deselectProfile={this.onProfileDeselected}
                updateProfile={this.handleProfileUpdate}
                onProfileDeleted={this.onProfileDeleted}
                onProfileNameChanged={this.onProfileNameChanged}
                updateAssets={this.updateAssets}
                pushSuccessToast={this.props.pushSuccessToast}
            />
        );
    }

    handleProfileUpdate = (profile) => {
        const profiles = deepClone(this.state.profiles);
        for (let i = 0; i < profiles.length; i++) {
            if (profiles[i].target.id === profile.target.id) {
                profiles[i] = profile;
                break;
            }
        }

        this.updateProfiles(profiles);
    };

    getContainerToShow() {
        if (this.state.selectedProfileId == null) {
            return this.showProfilesContainer();
        }
        return this.showProfileSelectedContainer();
    }

    onNewProfileCreated = (name) => {
        let newGuid = CreateNewGuid();

        while (this.profileGuidRepeat(newGuid)) {
            newGuid = CreateNewGuid();
        }

        const newProfile = {
            target: {
                id: newGuid,
                targetState: []
            },
            profile: {
                profileName: name,
                targetRefs: [{ id: newGuid }],
                settings: []
            }
        };

        const profiles = deepClone(this.state.profiles);
        profiles.push(newProfile);
        this.updateProfiles(profiles);

        // For some reason, focusing has issues when adding new profiles or deleting existing ones. It won't focus on the
        // the last one in the list when adding. I think it might have something to do with the modal and how it works with the
        // componentDidUpdate hook. Instead, just show a toast as adding new profiles isn't a common occurance.
        this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'profile-added-success-toast' }, { profileName: name }));
    };

    onProfileDeleted = (guid) => {
        const profileIndex = this.state.profiles.findIndex((profile) => profile.target.id === guid);

        if (profileIndex === -1) {
            return;
        }

        if (this.state.selectedProfileId != null && this.state.selectedProfileId === guid) {
            this.setState({ selectedProfileId: null });
        }

        const profileName = this.state.profiles[profileIndex].profile.profileName;
        const profiles = deepClone(this.state.profiles);
        profiles.splice(profileIndex, 1);
        this.updateProfiles(profiles);

        // For some reason, focusing has issues when adding new profiles or deleting existing ones. It won't focus on the
        // the last one in the list when adding. I think it might have something to do with the modal and how it works with the
        // componentDidUpdate hook. Instead, just show a toast as deleting new profiles isn't a common occurance.
        this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'profile-deleted-success-toast' }, { profileName: profileName }));
    };

    onProfileNameChanged = (updatedProfile) => {
        const profileIndex = this.state.profiles.findIndex((profile) => profile.target.id === updatedProfile.targetRefs[0].id);

        if (profileIndex === -1) {
            return;
        }

        const profiles = deepClone(this.state.profiles);
        profiles[profileIndex].profile = updatedProfile;
        this.updateProfiles(profiles);
    };

    profileGuidRepeat(guid) {
        return this.state.profiles.filter((profile) => profile.target.id === guid).length > 0;
    }

    getLoadingScreen() {
        return <LoadingSpinner loadingTextId={this.state.loadingMessageId} />;
    }

    pushSuccessToast(bodyId, bodyText) {
        if (!!this.props.pushToast) {
            this.props.pushToast({
                variant: 'success',
                titleId: 'toast-title-success',
                bodyId: bodyId,
                bodyText: bodyText
            });
        }
    }

    pushErrorToast(bodyId, bodyText) {
        if (!!this.props.pushToast) {
            this.props.pushToast({
                variant: 'danger',
                titleId: 'toast-title-danger',
                bodyId: bodyId,
                bodyText: bodyText
            });
        }
    }

    isSaveButtonDisabled() {
        return !this.state.isEdited;
    }

    isSubmitButtonDisabled() {
        return !this.state.isEdited && !this.state.isDraft && !this.state.isReadyForSubmission;
    }

    onDraftDetailsUpdated = (newDetails) => {
        this.setState({ updatedDraftDetails: newDetails });
    };

    onSubmitDraftButtonClicked = () => {
        this.handleShow('showSubmitDraftModal');
        const newDraftDetails = deepClone(this.props.draft);
        this.setState({ updatedDraftDetails: newDraftDetails });
    };

    getSaveButton() {
        if (!this.state.isDraft) {
            return '';
        }

        return (
            <Form.Group>
                <Button
                    className='btn-save-draft'
                    variant='success'
                    disabled={this.isSaveButtonDisabled()}
                    onClick={() => this.saveDraft('')}
                >
                    <FontAwesomeIcon className='mr-1' icon={faSave} size='sm' />
                    {this.intl.formatMessage({ id: 'save-draft' })}
                </Button>
            </Form.Group>
        );
    }

    render() {
        let buttonIconClassName = 'mr-1';
        let buttonIconSize = 'sm';

        if (this.state.loading) {
            return <div>{this.getLoadingScreen()}</div>;
        }

        return (
            <div>
                <div className='cosa-container'>{this.getContainerToShow()}</div>

                <div>
                    <div className='p-1' />
                    <BottomRowButtonActions>
                        <Form.Group>
                            <Button className='btn-showPpkg' variant='primary' onClick={() => this.handleShow('showPpkgModal')}>
                                <FontAwesomeIcon className={buttonIconClassName} icon={faDownload} size={buttonIconSize} />
                                {this.intl.formatMessage({ id: 'download-test-package' })}
                            </Button>
                        </Form.Group>
                        {this.getSaveButton()}
                        <Form.Group>
                            <Button
                                className='btn-submit-draft'
                                variant='primary'
                                disabled={this.isSubmitButtonDisabled()}
                                onClick={this.onSubmitDraftButtonClicked}
                            >
                                <FontAwesomeIcon className={buttonIconClassName} icon={faUpload} size={buttonIconSize} />
                                {this.state.isDraft
                                    ? this.intl.formatMessage({ id: 'submit' })
                                    : this.intl.formatMessage({ id: 'mps-save-sub' })}
                            </Button>
                        </Form.Group>
                    </BottomRowButtonActions>
                </div>
                <div>
                    <SaveDraftModal
                        show={this.state.showSaveDraftModal}
                        handleClose={() => this.handleClose('showSaveDraftModal')}
                        handleSubmit={(message) => this.saveDraft(message)}
                    />
                    <SubmitDraftModal
                        show={this.state.showSubmitDraftModal}
                        draftDetails={this.state.updatedDraftDetails}
                        onDraftDetailsUpdated={this.onDraftDetailsUpdated}
                        handleClose={() => this.handleClose('showSubmitDraftModal')}
                        handleSubmit={(message) => this.submitDraft(message)}
                    />
                    <CreatePpkgModal
                        show={this.state.showPpkgModal}
                        handleClose={() => this.handleClose('showPpkgModal')}
                        handleSubmit={(option) => this.generatePpkg(option)}
                    />
                    <BackConfirmationDialog
                        show={this.state.showBackConfirmation}
                        handleClose={() => this.handleClose('showBackConfirmation')}
                        handleSubmit={() => this.goBackToLanding()}
                    />
                </div>
                <Form.Group /* EMPTY */ />
            </div>
        );
    }
}

export default injectIntl(CosaContainer);
