// Copyright (C) Microsoft Corporation. All rights reserved.
import { MicrosoftSpinner } from '../controls/animation/MicrosoftSpinner';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import './LoadingSpinner.css';

export class LoadingSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ariaText: ''
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ ariaText: this.props.intl.formatMessage({ id: this.props.loadingTextId }) });
        }, 50);
    }

    render() {
        return (
            <div className='row mt-5 text-center justify-content-center align-self-center'>
                <MicrosoftSpinner />
                <h2 className='d-inline-block text-center justify-content-center align-self-center'>
                    {this.props.intl.formatMessage({ id: this.props.loadingTextId })}
                </h2>   
                <p class='visually-hidden' aria-live='polite'>{this.state.ariaText}</p>
            </div>
        );
    }
}

export default injectIntl(LoadingSpinner);
