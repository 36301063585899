import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import './DraftSubmissionColumn.css';

export const ColumnSelection = {
    DRAFTS: 1,
    SUBMISSIONS: 2
};

export class DraftSubmissionColumn extends Component {
    handleButtonClick = (buttonValue) => {
        this.props.handlePageClick(buttonValue);
    };

    isButtonSelected(buttonType) {
        return this.props.selectedPage === buttonType;
    }

    getButtonVariant(buttonType) {
        if (this.isButtonSelected(buttonType)) {
            return 'primary';
        }

        return 'outline-primary';
    }

    getAriaCurrent(buttonType) {
        return this.isButtonSelected(buttonType) ? 'true' : 'false';
    }

    render() {
        return (
            <div className='test'>
                <Button
                    variant={this.getButtonVariant(ColumnSelection.DRAFTS)}
                    onClick={() => this.handleButtonClick(ColumnSelection.DRAFTS)}
                    aria-current={this.getAriaCurrent(ColumnSelection.DRAFTS)}
                >
                    {this.props.intl.formatMessage({ id: 'drafts' })}
                </Button>
                <Button
                    variant={this.getButtonVariant(ColumnSelection.SUBMISSIONS)}
                    className='submission-button'
                    onClick={() => this.handleButtonClick(ColumnSelection.SUBMISSIONS)}
                    aria-current={this.getAriaCurrent(ColumnSelection.SUBMISSIONS)}
                >
                    {this.props.intl.formatMessage({ id: 'submissions' })}
                </Button>
            </div>
        );
    }
}

export default injectIntl(DraftSubmissionColumn);
