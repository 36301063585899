import { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

export const FilterButtonState = {
    NO_BUTTON_SELECTED: 0,
    COSA_BUTTON_SELECTED: 1,
    MPS_BUTTON_SELECTED: 2
};

const FilterButtonNames = {
    COSA: 'cosa',
    MPS: 'mps'
};

export class CosaMpsFilterButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedButton: FilterButtonState.NO_BUTTON_SELECTED
        };
    }

    isButtonSelected = (button) => {
        if (this.state.selectedButton === FilterButtonState.NO_BUTTON_SELECTED) {
            return false;
        } else if (button === FilterButtonNames.COSA) {
            return this.state.selectedButton === FilterButtonState.COSA_BUTTON_SELECTED;
        } else if (button === FilterButtonNames.MPS) {
            return this.state.selectedButton === FilterButtonState.MPS_BUTTON_SELECTED;
        }

        return false;
    };

    getVariantName = (buttonName) => {
        if (this.isButtonSelected(buttonName)) {
            return 'primary';
        }

        return 'outline-primary';
    };

    onButtonClick = (buttonName) => () => {
        let newState = -1;

        if (buttonName === FilterButtonNames.COSA) {
            // If the COSA button was clicked but the button was already selected, deselect it. Otherwise, select it.
            newState =
                this.state.selectedButton === FilterButtonState.COSA_BUTTON_SELECTED
                    ? FilterButtonState.NO_BUTTON_SELECTED
                    : FilterButtonState.COSA_BUTTON_SELECTED;
        } else if (buttonName === FilterButtonNames.MPS) {
            // If the MPS button was clicked but the button was already selected, deselect it. Otherwise, select it.
            newState =
                this.state.selectedButton === FilterButtonState.MPS_BUTTON_SELECTED
                    ? FilterButtonState.NO_BUTTON_SELECTED
                    : FilterButtonState.MPS_BUTTON_SELECTED;
        }

        this.setState({ selectedButton: newState });
        this.props.onFilterButtonClicked(newState);
    };

    getAriaCurrentValue(buttonName) {
        return this.isButtonSelected(buttonName) ? 'true' : 'false';
    }

    render() {
        return (
            <div style={{ maxWidth: '668px' }}>
                <Row>
                    <Col md={2}>
                        <p>Filter by:</p>
                    </Col>
                    <Col md={4}>
                        <Button
                            aria-label={this.props.intl.formatMessage({ id: 'landing-filter-button-cosa-aria' })}
                            variant={this.getVariantName(FilterButtonNames.COSA)}
                            onClick={this.onButtonClick(FilterButtonNames.COSA)}
                            aria-current={this.getAriaCurrentValue(FilterButtonNames.COSA)}
                            style={{ minWidth: '68px' }}
                        >
                            {this.props.intl.formatMessage({ id: 'cosa' })}
                        </Button>
                        <Button
                            aria-label={this.props.intl.formatMessage({ id: 'landing-filter-button-mps-aria' })}
                            variant={this.getVariantName(FilterButtonNames.MPS)}
                            onClick={this.onButtonClick(FilterButtonNames.MPS)}
                            aria-current={this.getAriaCurrentValue(FilterButtonNames.MPS)}
                            style={{ minWidth: '68px', marginLeft: '12px' }}
                        >
                            {this.props.intl.formatMessage({ id: 'mps' })}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(CosaMpsFilterButtons);
